import React, { useEffect } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SweetSpot.module.scss"
// import arrowRight from "../../images/ai-development-page/arrowRight.svg"
import { gsap, Power2 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from "gatsby"

gsap.registerPlugin(ScrollTrigger)
const mm = gsap.matchMedia()

const SweetSpot = ({ strapiData }) => {
  useEffect(() => {
    mm.add("(min-width: 1024px)", () => {
      const section = `#ell${0}`
      const image = `#imgg${0}`
      const section1 = `#ell${1}`
      const image1 = `#imgg${1}`
      const section2 = `#ell${2}`
      const image2 = `#imgg${2}`
      const section3 = `#ell${3}`
      const image3 = `#imgg${3}`
      let tl = gsap.timeline()

      tl.fromTo(
        image,
        { y: -350, opacity: 1 },
        {
          y: 400,
          opacity: 1,
          rotation: 60,
          scrollTrigger: {
            trigger: section,
            start: "top 45%",
            end: "bottom 10%",
            scrub: true,
            ease: Power2.easeInOut,
          },
        }
      )

      tl.fromTo(
        image1,
        { y: -400, opacity: 1 },
        {
          y: 400,
          opacity: 1,
          rotation: 90,
          scrollTrigger: {
            trigger: section1,
            start: "top 70%",
            end: "bottom top",
            scrub: true,
            ease: Power2.easeInOut,
          },
        }
      )

      tl.fromTo(
        image2,
        { y: -450, opacity: 1 },
        {
          y: 400,
          opacity: 1,
          rotation: 90,
          scrollTrigger: {
            trigger: section2,
            start: "top 70%",
            end: "bottom top",
            scrub: true,
            ease: Power2.easeInOut,
          },
        }
      )

      tl.fromTo(
        image3,
        { y: -500, opacity: 1 },
        {
          y: 400,
          opacity: 1,
          rotation: 90,
          scrollTrigger: {
            trigger: section3,
            start: "top 70%",
            end: "bottom top",
            scrub: true,
            ease: Power2.easeInOut,
          },
        }
      )

      return () => {
        ScrollTrigger.getAll().forEach(trigger => trigger.kill())
      }
    })
  }, [])

  return (
    <div className={styles.sectionContainer}>
      <Container className={styles.headingContainer}>
        <div>
          <h2
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />

          <p className={styles.desc}>{strapiData?.subTitle}</p>
        </div>
      </Container>
      <div className={styles.aiPoweredCard}>
        <Container>
          {strapiData?.cards?.map((el, ind) => (
            <Row
              className={styles.cardContent}
              key={`ai_powered_${el?.title}`}
              id={`ell${ind}`}
            >
              <Col xs={12} sm={12} md={6} lg={7}>
                <p className={styles.counting}>{`.0${ind + 1}`}</p>

                <h3
                  className={styles.cardHeading}
                  dangerouslySetInnerHTML={{
                    __html: el?.title,
                  }}
                />
                <p className={styles.cardDescription}>{el?.subTitle}</p>
                <Row className={styles.keyPoints}>
                  {el?.jsonData?.aiPowered?.services?.map(item => (
                    <Col xs={12} sm={12} md={6} key={item?.serName}>
                      <div className={styles.keyPoint}>
                        <img src={arrowRight} alt={"arrow"} />
                        <Link to={`${item?.serLink}`}>
                          <p>{item?.serName}</p>
                        </Link>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6} lg={5} style={{ overflow: "hidden" }}>
                <div id={`imgg${ind}`}>
                  <img
                    src={el?.image1?.[0]?.localFile?.publicURL}
                    alt={"img"}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </div>
  )
}

export default SweetSpot
