import { Script, graphql } from "gatsby"
import React from "react"
// import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import Banner from "../../components/machine-development/Banner"
import ClientLogos from "../../components/home-sections/ClientLogos"
import ClientLogosMobile from "../../components/home-sections/ClientLogosMobile"
import MainLayout from "../../layouts/MainLayout"
import ContactSales from "../../components/common/ContactSales"
import Capabilities from "../../components/machine-development/Capabilities"
import PartnerSuccess from "../../components/machine-development/Partner-success"
import EngagementModal from "../../components/machine-development/EngagmentModal"
import InvoZoneGuarantee from "../../components/machine-development/Invozone-guarante"
import SuccessStories from "../../components/containerization-sections/success-stories"
import Tabs from "../../components/machine-development/Tabs"
import TabsMobile from "../../components/machine-development/TabsMobile"
import OurMlServices from "../../components/machine-development/OurMlServices"
import SweetSpot from "../../components/machine-development/SweetSpot"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}
// const dedicatedSoftware = data?.strapiPage?.sections[2]

const MLServices = ({ data }) => {
  console.log("DigitalExperience", data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const globalIndustires = [
    data?.strapiPage?.sections[3],
    data?.strapiPage?.sections[4],
  ]

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const mlServices = data?.strapiPage?.sections[1]
  const sweetSpot = data?.strapiPage?.sections[2]
  const chooseIZ = data?.strapiPage?.sections[3]
  const feedback = data?.strapiPage?.sections[4]
  const realWorld = data?.strapiPage?.sections[5]
  const faqs = data?.strapiPage?.sections[6]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <div className="client-logos-container">
        {!isMobile && !isTablet ? <ClientLogos /> : <ClientLogosMobile />}
      </div>
      <OurMlServices strapiData={mlServices}/>
      <SweetSpot strapiData={sweetSpot}/>
      <Capabilities strapiData={chooseIZ} />
      <PartnerSuccess strapiData={feedback} />
      <SuccessStories strapiData={realWorld} />
      <Faqs strapiData={faqs} pageName="customer-experience" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query machineDevelopment {
    strapiPage(slug: { eq: "machine-learning-development-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default MLServices
